// src/renderComponents.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Banner from './components/Banner/Banner';
import AboutUs from './components/About/AboutUs';
import Showcase from './components/Showcase/Showcase';
import ContactUs from './components/Contact/ContactUs';
import Scroll from './components/ScrollToTop/ScrollToTop';

function renderComponent(Component, elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    const root = createRoot(element);
    root.render(<Component />);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  renderComponent(Navbar, 'navbar-root');
  renderComponent(Footer, 'footer-root');
  renderComponent(Banner, 'banner-root');
  renderComponent(AboutUs, 'about-us-root');
  renderComponent(Showcase, 'showcase-root');
  renderComponent(ContactUs, 'contact-us-root');
  renderComponent(Scroll, 'scroll-root');
});