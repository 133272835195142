// src/components/Navbar/Navbar.js
import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const menuAnimation = useSpring({
    height: isOpen ? '200px' : '0px',
    opacity: isOpen ? 1 : 0,
    overflow: 'hidden',
    config: {
      // duration: 200, // Duration in milliseconds
      tension: 450, // Default is 170, higher values make the animation faster
      friction: 20, // Default is 26, lower values make the animation smoother
    },
  });

  return (
    <nav className="navbar" id='home'>
      <div className="navbar-brand">Mayhew</div>
      <button className="navbar-toggler" onClick={handleToggle}>
        &#9776;
      </button>
      <animated.ul style={menuAnimation} className="navbar-menu">
        <li className="navbar-item"><a href="#home">Home</a></li>
        <li className="navbar-item"><a href="#about-us">Meet Me</a></li>
        <li className="navbar-item"><a href="#showcase">Showcase</a></li>
        <li className="navbar-item"><a href="#contact">Contact</a></li>
      </animated.ul>
    </nav>
  );
};

export default Navbar;